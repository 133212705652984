<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div class="w-100" v-if="reserveData !== null">

      <!-- Table Container Card -->

        <div class="p-0">
          <div class="checkout-section section section-padding-bottom p-0">
            <div class="container">
              <div class="row">
                <div class="col-12">

                  <!-- Checkout Form Start-->
                  <div class="row row-40">

                    <div class="col-12 px-0">
                      <div class="row">

                        <!-- Cart Total -->
                        <div id="ticket-section" class="col-12 max-mb-60">


                          <div v-for="(item,idx) in reserveData.detailData" v-if="!item.reserve.vip"
                               class="position-relative">

                            <img
                                :src="blit"
                                width="100%"
                                height="1400px"
                                alt="بلیط">

                            <span v-if="reserveData.section.level === 1"
                                  class="position-absolute font-weight-bold "
                                  style="font-size:35px;bottom:450px;left: 210px">{{
                                reserveData.section.name
                              }} همکف</span>
                            <span v-else-if="reserveData.section.level === 2"
                                  class="position-absolute font-weight-bold "
                                  style="font-size:35px;bottom:450px;left: 210px">{{
                                reserveData.section.name
                              }} بالکن</span>
                            <span class="position-absolute font-weight-bold"
                                  style="font-size:35px;bottom: 400px;left: 300px">{{
                                item.row.rowNumber
                              }}</span>
                            <span class="position-absolute font-weight-bold"
                                  style="font-size:35px;bottom: 350px;left: 290px">{{
                                item.seat.seatNumber
                              }}</span>
                            <span class="position-absolute font-weight-bold"
                                  style="font-size:20px;bottom:305px;right: 790px">{{
                                reserveData.userInfo.name
                              }} {{ reserveData.userInfo.familyName }}</span>
                            <span class="position-absolute font-weight-bold"
                                  style="font-size:35px;bottom: 250px;left: 90px">{{
                                reserveData.userInfo.mobile
                              }}</span>
                            <span class="position-absolute font-weight-bold"
                                  style="font-size:35px;bottom: 200px;left: 200px">{{ orderId }}</span>

                            <img class="position-absolute" style="bottom: 50px;left: 40px" data-not-lazy
                                 :src="item.seat.qrCode" width="100px" height="100px" alt="بارکد">
                          </div>

                           <div v-for="(item,idx) in reserveData.detailData" v-if="item.reserve.vip"
                               class="position-relative" style="min-height: 1500px">

                            <img
                                :src="blitVip"
                                width="100%"
                                height="800px"
                                alt="بلیط">

                            <span v-if="reserveData.section.level === 1"
                                  class="position-absolute font-weight-bold text-white"
                                  style="font-size:30px;top:350px;right: 470px">{{
                                reserveData.section.name
                              }} همکف</span>
                            <span v-else-if="reserveData.section.level === 2"
                                  class="position-absolute font-weight-bold text-white"
                                  style="font-size:30px;top:350px;right: 470px">{{
                                reserveData.section.name
                              }} بالکن</span>
                            <span class="position-absolute font-weight-bold text-white"
                                  style="font-size:30px;top: 390px;right: 500px">{{
                                item.row.rowNumber
                              }}</span>
                            <span class="position-absolute font-weight-bold text-white"
                                  style="font-size:30px;top: 430px;right: 490px">{{
                                item.seat.seatNumber
                              }}</span>
                            <span class="position-absolute font-weight-bold text-white"
                                  style="font-size:25px;top:470px;right: 560px">{{
                                reserveData.userInfo.name
                              }} {{ reserveData.userInfo.familyName }}</span>
                            <span class="position-absolute font-weight-bold text-white"
                                  style="font-size:30px;top: 510px;right: 510px">{{
                                reserveData.userInfo.mobile
                              }}</span>
                            <span class="position-absolute font-weight-bold text-white"
                                  style="font-size:35px;top: 550px;right: 510px">{{ orderId }}</span>

                            <img class="position-absolute" style="top: 580px;left: 105px" data-not-lazy
                                 :src="item.seat.qrCode" width="125px" height="120px" alt="بارکد">
                          </div>

                        </div>
                        <div class="col-10 col-md-12 mx-auto p-0 mt-5">

                          <button @click="generatePdf" type="button"
                                  class="btn btn-info text-white bg-HM-dark btn-hover-secondary btn-width-100 mt-40 col-12 col-md-2 ">
                            دانلود بلیت
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import {OrderGetRequest} from "@/libs/Api/Order";
import Helper from "@/libs/Helper";
import {ReserveGetReserveDataByOrderIdRequest} from "@/libs/Api/Reserve";
export default {
  title:"بلیط کاربر - پنل ادمین مکس ",
  name: "SeminarTicket",
  data(){
    return{
      order:[],
      orderId:this.$route.params.id,
      reserveData:null,
      blit:require('@/assets/images/seminar/blit.png'),
      blitVip:require('@/assets/images/seminar/blit-vip.png'),
      showOverlay:false,
      myTableColumns : [
        { key: 'orderId' ,label :'شناسه سفارش'},
        { key: 'userInfo.userId' ,label :'اطلاعات کاربر'},
        { key: 'isPayed' ,label :'وضعیت پرداخت'},
        // { key: 'description' ,label :'توضیحات'},
        { key: 'sumPrice' ,label :'مجموع'},
        { key: 'isDelivered',label: 'ترابری'},
        { key: 'createDate',label: 'تاریخ ثبت'},
        { key: 'status',label: 'وضعیت'},
      ],
      myTableColumnsSubOrders:[
        { key: 'productId' ,label :'شناسه محصول'},
        { key: 'productInfo' ,label :'نام محصول'},
        { key: 'courseScheduleId' ,label :'شناسه زمانبندی دوره'},
        { key: 'count' ,label :'تعداد'},
        { key: 'amount' ,label :'قیمت'},
      ],
    }
  },
  async created(){
    // await this.getOrder();
    await this.getReserveData();
  },
  methods:{
    // async getOrder(){
    //   let _this = this;
    //   _this.showOverlay = true;
    //
    //   let orderGetRequest = new OrderGetRequest(_this);
    //   orderGetRequest.setId(_this.orderId);
    //   await orderGetRequest.fetch(function (content){
    //     _this.showOverlay = false;
    //     _this.order.push(content)
    //   },function (error){
    //     console.log(error)
    //     _this.showOverlay = false;
    //   })
    // },
    async getReserveData() {
      let _this = this;
      _this.showOverlay = true;

      let reserveGetReserveDataByOrderIdRequest = new ReserveGetReserveDataByOrderIdRequest(_this);
      reserveGetReserveDataByOrderIdRequest.setOrderId(_this.orderId);
      await reserveGetReserveDataByOrderIdRequest.fetch(function (content) {
        _this.showOverlay = false;
        _this.reserveData = content;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    makePrettyPrice(param){
      return Helper.prettyPrice(param,0)
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    generatePdf() {
      window.print();
    },
    handleBeforePrint() {
      let ticket = document.getElementById('ticket-section');
      ticket.style.display = 'block';
    },
    handleAfterPrint() {
      let ticket = document.getElementById('ticket-section');
      ticket.style.display = 'none';
    }
  },
  mounted() {
    window.addEventListener('beforeprint', this.handleBeforePrint)
    window.addEventListener('afterprint', this.handleAfterPrint)
  },
  beforeDestroy() {
    window.removeEventListener('beforeprint', this.handleBeforePrint)
    window.removeEventListener('afterprint', this.handleAfterPrint)
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,

    vSelect,
  },
}
</script>

<style scoped>
#ticket-section {
  display: none;
}

.vertical-layout > nav{
  display: none!important;
  width: 0!important;
  height: 0!important;
}

.main-menu , .navbar  , .header-navbar-shadow{
  display: none!important;
  width: 0!important;
}

.content .app-content{
  padding: 0!important;
}

@media print {
  body {
    margin: 0;
    color: #000;
    background-color: #fff;
  }

  #page {
    width: 100%;
    margin-left: 100px;
  }

  .btn, .header-font-size, .page-breadcrumb, .footer-section, .scroll-top, .factor, #scroll-top, .page-title-section  {
    display: none;
  }

  .main-menu , .navbar , .header-navbar-shadow{
    display: none!important;
    width: 0!important;
    height: 0!important;
  }

  .content .app-content{
    padding: 0!important;
  }

  @page {
    /*margin: 2cm;*/
  }
}
</style>